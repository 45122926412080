.button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 10px;
}

.delete-button {
    background-color: var(--button-color-del);
    color: var(--button-color-del-text);
}

a:visited {
    color: #012d8a;
}

a:link {
    color: #012d8a;
}

a:hover {
    color: #0058d3;
}

a:active {
    color: #0058d3;
}
