@import "colours.css";

.course-details {
    padding: 20px;
    margin: auto;
}

h1 {
    text-align: center;
    margin-bottom: 10px;
}

p {
    /*text-align: center;*/
    color: #555;
}

.modules-section,
.groups-section {
    margin-top: 30px;
}

.module {
    margin-bottom: 15px;
}

h3 {
    margin-bottom: 5px;
}

.assignments-list {
    list-style-type: disc;
    padding-left: 20px;
}

.group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.group-invite {
    display: flex;
    align-items: center;
}

.copy-button {
    color: var(--button-color-text);
}

.copy-button svg {
    vertical-align: middle;
}

.course-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.course-box-title {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 1200px;
}

.modal-input {
    width: calc(100% - 20px);
    display: flex;
}

.modal-textarea {
    width: calc(100%);
    height: fit-content;
}

.modal-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.group {
    margin-bottom: 15px;
}
.member-list {
    margin-bottom: 15px;
    padding-left: 40px;
}

.assignment-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}

div.module {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
}
