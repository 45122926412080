textarea {
    width: 100%;
    max-width: 100%;  /* Prevent textarea from growing beyond container width */
    min-height: 50px; /* Set an initial minimum height */
    resize: none;     /* Disable manual resizing by the user */
    overflow: hidden; /* Hide the scrollbar */
    box-sizing: border-box; /* Include padding and border in the element's total width/height */
}

/* JavaScript to make the textarea grow as content is entered */
textarea.auto-resize {
    display: block;
    overflow-y: hidden;
}

div.question-editor, div.variant-editor {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
}

button.active {
    border: 2px solid black;
    font-weight: bold;
}

.option-item {
    display: flex;
    align-items: center; /* Aligns checkbox and text vertically */
    margin-bottom: 10px; /* Adds space after each option (line break effect) */
}

.option-index {
    min-width: 30px; /* Ensures a fixed space for the index */
    text-align: right; /* Align the index to the right */
    margin-right: 10px; /* Space between index and option text */
}

.option-text {
    display: inline-block;
    text-indent: 20px; /* Indents the text by a fixed amount */
}

.variant-text {
    margin-bottom: 10px;
    display: flex;
}

.variant-editor {
    margin-bottom: 0;
}

.option-preview {
    display: flex;
}

textarea.auto-resize {
    margin-left: 10px;
    margin-right: 10px;
}

.question-text {
    margin-left: 10px;
}

.variants-hdr {
    margin-left: 10px;
    display: inline;
    font-weight: bold;
    font-size: larger;
}