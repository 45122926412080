@import "colours.css";
@import "elements.css";

/* Header container */
.header {
    width: 100%;
    background-color: #235ab5;
    padding: 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    z-index: 1000;
}

/* Content inside the header */
.header-content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Greeting text */
.greeting {
    font-size: 1.2rem;
    font-weight: bold;
}

a.home, a.home:visited {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
    margin-right: 40px;
    color: #c69300;
    text-decoration: none;
    text-underline-style: none;
}

/* Logout button */
.logout-button {
    background-color: var(--button-color-del);
    color: var(--button-color-del-text);
}

.logout-button:hover {
    background-color: var(--button-color-del-hover);
}

/* mode toggle */
.mode-button {
    background-color: var(--button-color);
    color: var(--button-color-text);
}

.mode-button:hover {
    background-color: var(--button-color-hover);
}
